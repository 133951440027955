import React, { FormEvent, useEffect, useMemo } from "react"
import { nameof2, nameof3 } from "../../../utility/common/nameof"
import DebouncedCheckBox from "../../CheckBoxValidatableInput/DebouncedCheckBox"
import { useTranslation } from "react-i18next"
import DebouncedValidatableInput from "../../ValidatableInput/DebouncedValidatableInput"
import {
    CustomMenusValue,
    CustomMenuValue,
    ProjectSettings,
    ProjectSettingsListValue,
    SecurityProjectSettings,
    SystemProjectSettings
} from "../../../models/projectSettings"
import FormContent from "../../FormHelpers/Content/FormContent"
import FormSection from "../../FormHelpers/Section/FormSection"
import { useDispatch, useSelector } from "react-redux"
import { selectProjectSettings, selectUpdateProjectSettingsState } from "../../../store/projects/selectors"
import ProjectSettingsLayout from "../../ProjectSettingsLayout/ProjectSettingsLayout"
import { Formik, FormikProps } from "formik"
import { SystemSettingsValues } from "../../../models/projectSettingsValues"
import { Form } from "react-bootstrap"
import { formTranslation } from "../../../locales/form"
import FormSectionTitle from "../../FormHelpers/SectionTitle/FormSectionTitle"
import InfoIcon from "../../InfoIcon/InfoIcon"
import ValidatableInput from "../../ValidatableInput/ValidatableInput"
import FormControlWithAdd from "../../FormHelpers/ControlWithAdd/FormControlWithAdd"
import { useDifferenceCount } from "../../../utility/project/projectSettings"
import { updateProjectSettings } from "../../../store/projects/thunks"
import { preventSubmitOnEnter } from "../../../utility/common/preventSubmitOnEnter"
import {
    buildUpdateSystemSettingsRequest,
    defaultSystemProjectSettings,
    getSystemSettingsInitialValues
} from "./helpers"
import { testId } from "../../../utility/tests/testId"
import ConvertTemplateControl from "../controls/ConvertTemplateControl/ConvertTemplateControl"
import { selectSurveys } from "../../../store/surveys/selectors"
import { getSurveys } from "../../../store/surveys/thunks"
import ImageUploadInput from "../../ImageUploadInput/ImageUploadInput"

const tNamespace = "project:project-settings.system."
const tTooltipNamespace = `${tNamespace}tooltip.`

interface FormProps {
    projectId: string
    onChange: (e: FormEvent<HTMLFormElement>, initialValues: SystemSettingsValues, values: SystemSettingsValues) => void
    onCustomInputChange: <K>(name: string, value: K, initialValues: SystemSettingsValues) => void
}

const FormikSystemProjectSettingsForm: React.FC<FormikProps<SystemSettingsValues> & FormProps> = props => {
    const { initialValues, values, onChange, onCustomInputChange, projectId } = props
    const surveys = useSelector(selectSurveys)

    const { t } = useTranslation()

    return (
        <Form
            onChange={e => onChange(e, initialValues, values)}
            data-test-id={testId.projectSystemSettingsForm}
            onKeyPress={preventSubmitOnEnter}
        >
            <ProjectSettingsLayout.Section border>
                <FormContent>
                    <FormSection>
                        <DebouncedCheckBox
                            id="formUpdateClientDataFromChannel"
                            name={nameof2<ProjectSettings, SystemProjectSettings>(
                                "System",
                                "UpdateClientDataFromChannel"
                            )}
                            label={t(`${tNamespace}update-client-data-from-channel`)}
                        />
                        <DebouncedCheckBox
                            id="formChannelOnDialogPanel"
                            name={nameof2<ProjectSettings, SystemProjectSettings>("System", "ChannelOnDialogPanel")}
                            label={t(`${tNamespace}channel-on-dialog-panel`)}
                        />
                        <DebouncedValidatableInput
                            id="formDialogSwitchNotificationWebhook"
                            label={t(`${tNamespace}dialog-switch-notification-webhook`)}
                            name={nameof2<ProjectSettings, SystemProjectSettings>(
                                "System",
                                "DialogSwitchNotificationWebhook"
                            )}
                        />
                        <DebouncedValidatableInput
                            id="formSwitchToBotWindowTitle"
                            type="text"
                            name={nameof2<ProjectSettings, SystemProjectSettings>("System", "SwitchToBotWindowTitle")}
                            label={t(`${tNamespace}switch-to-bot-window-title`)}
                        />
                        <DebouncedValidatableInput
                            id="formSwitchToBotWindowMessage"
                            type="text"
                            name={nameof2<ProjectSettings, SystemProjectSettings>("System", "SwitchToBotWindowMessage")}
                            label={t(`${tNamespace}switch-to-bot-window-message`)}
                        />
                        <DebouncedCheckBox
                            id="formDisableMarkupModal"
                            name={nameof2<ProjectSettings, SystemProjectSettings>("System", "DisableMarkupModal")}
                            label={t(`${tNamespace}disable-markup-modal`)}
                        />
                        <DebouncedValidatableInput
                            id="formOperatorQuestionaryId"
                            as="select"
                            disabled={!surveys.length}
                            name={nameof2<ProjectSettings, SystemProjectSettings>("System", "OperatorQuestionaryId")}
                            label={t(`${tNamespace}operator-survey`)}
                        >
                            <option value="unknown">{t(formTranslation.nothingSelected)}</option>
                            {surveys.map(option => (
                                <option value={option.Id} key={option.Id}>
                                    {option.Title}
                                </option>
                            ))}
                        </DebouncedValidatableInput>
                        <DebouncedCheckBox
                            id="formOperatorWorkplacePersonalization"
                            name={nameof2<ProjectSettings, SystemProjectSettings>(
                                "System",
                                "EnableMessageSendBehaviour"
                            )}
                            label={t(`${tNamespace}enable-send-message-behaviour`)}
                        />
                        <DebouncedCheckBox
                            id="formAdditionalResendConfigDependsOnQueue"
                            name={nameof2<ProjectSettings, SystemProjectSettings>(
                                "System",
                                "AdditionalResendConfigDependsOnQueue"
                            )}
                            label={t(`${tNamespace}free-filling-for-forward-email-template`)}
                        />
                    </FormSection>
                </FormContent>
            </ProjectSettingsLayout.Section>
            <ProjectSettingsLayout.Section border>
                <FormContent>
                    <FormSection>
                        <FormSectionTitle>{t(`${tNamespace}security`)}</FormSectionTitle>
                    </FormSection>
                    <FormSection>
                        <FormControlWithAdd
                            name={nameof3<ProjectSettings, SecurityProjectSettings, ProjectSettingsListValue>(
                                "Security",
                                "InformationMaskingPatterns",
                                "Values"
                            )}
                            label={t(`${tNamespace}information-masking-patterns`)}
                            icon={
                                <InfoIcon
                                    id="tooltipInformationMaskingPatterns"
                                    content={t(`${tTooltipNamespace}information-masking-patterns`)}
                                />
                            }
                            onCreateEmptyValue={() => ""}
                        >
                            {({ name, remove }) =>
                                values.Security.InformationMaskingPatterns.Values.map((_, index: number) => (
                                    <ValidatableInput
                                        key={index}
                                        id={`formInformationMaskingPatterns${index}`}
                                        as="textarea"
                                        name={`${name}[${index}]`}
                                        label={t(`${tNamespace}regular-expression`)}
                                        onDelete={() => {
                                            remove(index)
                                            onCustomInputChange(
                                                nameof3<
                                                    ProjectSettings,
                                                    SecurityProjectSettings,
                                                    ProjectSettingsListValue
                                                >("Security", "InformationMaskingPatterns", "Values"),
                                                values.Security.InformationMaskingPatterns.Values[index],
                                                initialValues
                                            )
                                        }}
                                    />
                                ))
                            }
                        </FormControlWithAdd>
                    </FormSection>
                </FormContent>
            </ProjectSettingsLayout.Section>
            <ProjectSettingsLayout.Section border>
                <FormContent>
                    <FormSection>
                        <FormSectionTitle>{t(`${tNamespace}knowledge-base`)}</FormSectionTitle>
                    </FormSection>
                    <FormSection>
                        <ConvertTemplateControl
                            projectId={projectId}
                            onChange={(name, value) => onCustomInputChange(name, value, initialValues)}
                        />
                    </FormSection>
                </FormContent>
            </ProjectSettingsLayout.Section>
            <ProjectSettingsLayout.Section>
                <FormSection>
                    <FormContent>
                        <FormControlWithAdd
                            name={nameof3<ProjectSettings, SystemProjectSettings, CustomMenusValue>(
                                "System",
                                "CustomMenus",
                                "Values"
                            )}
                            label={t(`${tNamespace}custom-menu-add`)}
                            icon={
                                <InfoIcon
                                    id="tooltipCustomSectionUrl"
                                    content={t(`${tTooltipNamespace}custom-menu-add`)}
                                />
                            }
                            onCreateEmptyValue={() => ""}
                        >
                            {({ name, remove }) =>
                                values.System.CustomMenus.Values?.map((v: CustomMenuValue, index: number) => (
                                    <FormSection key={index}>
                                        <ValidatableInput
                                            id="formCustomMenuTitle"
                                            name={`${name}[${index}].Title`}
                                            label={t(`${tNamespace}custom-menu-title`)}
                                            onDelete={() => {
                                                remove(index)
                                                onCustomInputChange(
                                                    nameof3<ProjectSettings, SystemProjectSettings, CustomMenusValue>(
                                                        "System",
                                                        "CustomMenus",
                                                        "Values"
                                                    ),
                                                    values.System.CustomMenus.Values[index],
                                                    initialValues
                                                )
                                            }}
                                        />
                                        <ValidatableInput
                                            id="formCustomMenuUrl"
                                            type="text"
                                            name={`${name}[${index}].Url`}
                                            label={t(`${tNamespace}custom-menu-url`)}
                                        />
                                        <ImageUploadInput
                                            id="formCustomMenuIcon"
                                            name={`${name}[${index}].Icon`}
                                            label={t(`${tNamespace}custom-menu-icon`)}
                                            inline
                                            onSave={v =>
                                                onCustomInputChange(`${name}[${index}].Icon`, v, initialValues)
                                            }
                                        />
                                    </FormSection>
                                ))
                            }
                        </FormControlWithAdd>
                    </FormContent>
                </FormSection>
            </ProjectSettingsLayout.Section>
        </Form>
    )
}

interface Props {
    projectId: string
    title: string
    open: boolean
}

const SystemProjectSettingsForm: React.FC<Props> = props => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const { projectId, title, open } = props
    const settings = useSelector(selectProjectSettings)
    const updateProjectSettingsState = useSelector(selectUpdateProjectSettingsState)

    const { differenceCount, onOpenSettings, onChange, onCustomInputChange, reset } = useDifferenceCount()
    const initialValues = useMemo(() => settings && getSystemSettingsInitialValues(settings), [settings])

    useEffect(() => {
        dispatch(getSurveys(projectId))
    }, [dispatch, projectId])

    useEffect(() => {
        if (open && settings) {
            onOpenSettings(defaultSystemProjectSettings, {
                Security: settings?.Security,
                System: settings?.System
            })
        }
    }, [onOpenSettings, settings, open])

    if (!initialValues) return null

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values: SystemSettingsValues) => {
                dispatch(updateProjectSettings(projectId, buildUpdateSystemSettingsRequest(values), reset))
            }}
        >
            {formikProps => (
                <ProjectSettingsLayout.Container
                    title={title}
                    onButtonClick={formikProps.handleSubmit}
                    buttonText={t(formTranslation.save)}
                    hideButton={!differenceCount}
                    notifications={differenceCount}
                    loading={updateProjectSettingsState.inProcess}
                >
                    <FormikSystemProjectSettingsForm
                        {...formikProps}
                        onChange={onChange}
                        onCustomInputChange={onCustomInputChange}
                        projectId={projectId}
                    />
                </ProjectSettingsLayout.Container>
            )}
        </Formik>
    )
}

export default SystemProjectSettingsForm
