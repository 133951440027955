import { Task, TaskDto } from "./task"

export enum QueueType {
    Auto,
    Manual
}

export interface TimeoutSettings {
    TimeoutQueueId: string
    TimeoutMSec: number
}

export interface IndividualTimeoutSettings {
    TimeoutMSec: number
}

export interface SlSettings {
    SlThresholdMSec: number
    SlPeriodMSec: number
    SlTarget: number
}

export interface WaitTimeSettings {
    Id: string
    LookbehindMSec: number
}

export interface FinishedDialogsSettings {
    Id: string
    LookbehindMSec: number
}

export interface DialogTimeoutSettings {
    Green: number
    Yellow: number
}

export interface OperatorAutoGreeting {
    OperatorAutoGreetingEnabled: boolean
    OperatorAfterHoldGreetingEnabled: boolean
    Template: string
}

export interface ClientFormSurvey {
    MainSurveyId: string
    ShortSurveyId: string
}

export interface AdditionalResendConfig {
    Enable: boolean
    Template: string
}

export interface EmailChannels {
    DefaultChannelId: string
    AdditionalChannelsIds: string[]
}

export interface KnowledgeBaseQueue {
    CatalogId: string | null
}

export interface AutoHoldingSettings {
    Enabled: boolean
    TriggerMessages: string[]
    MessagesToSend: string[]
    Timeout: number
    MultipleSending: boolean
}

export interface FinishedDialogsDailySettings {
    Id: string
    LocalResetTime: number
}

export interface CreateQueueGeneralRequest {
    Name: string
    Type?: QueueType
    TaskSize?: number
    Priority?: number
    TimeoutSettings?: TimeoutSettings
    SlSettings?: SlSettings
    AwtStatsSettings?: WaitTimeSettings[]
    FinishedDialogsSettings?: FinishedDialogsSettings[]
    FinishedDialogsDailySettings?: FinishedDialogsDailySettings[]
    RoutingWebHook?: string
}

export type CreateQueueInCategoryRequest = CreateQueueGeneralRequest & { CategoryId: string }

export interface UpdateQueueGeneralRequest {
    Name: string
    Type: QueueType
    TaskSize: number
    Priority: number
    TimeoutSettings: TimeoutSettings | null
    SlSettings: SlSettings | null
    AwtStatsSettings: WaitTimeSettings[] | null
    FinishedDialogsSettings?: FinishedDialogsSettings[] | null
    FinishedDialogsDailySettings?: FinishedDialogsDailySettings[] | null
    RoutingWebHook: string | null
}

export interface CreateQueueRequest {
    General: CreateQueueInCategoryRequest
    ExtendedSettings: ExtendedSettings
}

export interface ExtendQueueSettings {
    TenantId: string
    QueueId: string
    DialogTimeoutSettings: DialogTimeoutSettings
    OperatorAutoGreeting: OperatorAutoGreeting
    ClientFormSurvey: ClientFormSurvey
    AdditionalResendConfig: AdditionalResendConfig
    EmailChannels: EmailChannels
    KnowledgeBase: KnowledgeBaseQueue | null
    AutoHoldingSettings: AutoHoldingSettings
}

export interface UpdateQueueRequest {
    General: UpdateQueueInCategoryRequest
    ExtendedSettings: ExtendedSettings
}

export interface ExtendedSettings {
    DialogTimeoutSettings: DialogTimeoutSettings | null
    OperatorAutoGreeting: OperatorAutoGreeting | null
    ClientFormSurvey: ClientFormSurvey | null
    AdditionalResendConfig: AdditionalResendConfig | null
    EmailChannels: EmailChannels | null
    KnowledgeBase: KnowledgeBaseQueue | null
    AutoHoldingSettings: AutoHoldingSettings | null
}

export type UpdateQueueInCategoryRequest = UpdateQueueGeneralRequest & { CategoryId: string }

export interface UpdateIndividualQueuesRequest {
    Priority: number
    TimeoutSettings: IndividualTimeoutSettings
}

export interface OperatorsTasksCount {
    [id: string]: number
}

export interface FinishedDialogsSummary {
    Handled: number
    Lost: number
}

export interface FinishedDialogs {
    [id: string]: FinishedDialogsSummary
}

export interface FinishedDialogsDaily {
    [id: string]: FinishedDialogsSummary
}

export interface Awt {
    [id: string]: number
}

export interface Queue {
    Id: string
    TenantId: string
    Type: QueueType
    CategoryId: string
    Name: string
    TaskSize: number
    Priority: number
    ActiveTasksCount?: number
    TimeoutSettings?: TimeoutSettings
    IndividualPriority: number
    IndividualTimeoutSettings: IndividualTimeoutSettings
    SlSettings?: SlSettings
    AwtStatsSettings?: WaitTimeSettings[]
    FinishedDialogsSettings?: FinishedDialogsSettings[]
    FinishedDialogsDailySettings?: FinishedDialogsDailySettings[]
    RoutingWebHook?: string
    LastPickTime: number
    Sl?: number
    Awt: Awt
    FinishedDialogs: FinishedDialogs
    FinishedDialogsDaily: FinishedDialogsDaily
    CSI?: number
    OperatorsIds: string[]
    OperatorsTasksCount: OperatorsTasksCount
    PendingTasks: Task[]
    PendingIndividualTasks: Task[]
}

export interface QueueDto {
    Id: string
    TenantId: string
    Type: QueueType
    CategoryId: string
    Name: string
    TaskSize: number
    Priority: number
    ActiveTasksCount?: number
    TimeoutSettings?: TimeoutSettings
    IndividualPriority: number
    IndividualTimeoutSettings: IndividualTimeoutSettings
    SlSettings?: SlSettings
    AwtStatsSettings?: WaitTimeSettings[]
    FinishedDialogsSettings?: FinishedDialogsSettings[]
    FinishedDialogsDailySettings?: FinishedDialogsDailySettings[]
    RoutingWebHook?: string
    LastPickTime: number
    Sl?: number
    Awt: Awt
    FinishedDialogs: FinishedDialogs
    FinishedDialogsDaily: FinishedDialogsDaily
    CSI?: number
    OperatorsIds: string[]
    OperatorsTasksCount: OperatorsTasksCount
    PendingTasks: TaskDto[]
    PendingIndividualTasks: TaskDto[]
}

export interface QueueName {
    Id: string
    Name: string
}

export interface QueueAddedMsg {
    Queue: Queue
}

export interface QueueUpdatedMsg {
    QueueId: string
    CategoryId: string
    Name: string
    Type: QueueType
    TaskSize: number
    Priority: number
    TimeoutSettings?: TimeoutSettings
    IndividualPriority: number
    IndividualTimeoutSettings: IndividualTimeoutSettings
    SlSettings: SlSettings
    AwtStatsSettings: WaitTimeSettings[]
    FinishedDialogsSettings?: FinishedDialogsSettings[]
    FinishedDialogsDailySettings?: FinishedDialogsDailySettings[]
    RoutingWebHook?: string
    DialogTimeoutSettings: DialogTimeoutSettings | null
    OperatorAutoGreeting: OperatorAutoGreeting | null
}

export interface QueueSlUpdatedMsg {
    QueueId: string
    CategoryId: string
    Sl: number
}

export interface QueueAwtUpdatedMsg {
    QueueId: string
    CategoryId: string
    Awt: Awt
}

export interface QueueOperatorTasksCountUpdatedMsg {
    OperatorId: string
    Count: number
}

export interface QueueFinishedDialogsUpdatedMsg {
    QueueId: string
    CategoryId: string
    FinishedDialogs: FinishedDialogs
}

export interface QueueFinishedDialogsDailyUpdatedMsg {
    QueueId: string
    CategoryId: string
    FinishedDialogs: FinishedDialogsDaily
}

export interface QueueExtendSettingsUpdatedMsg {
    Settings: ExtendQueueSettings
}

export type MonitoringOverview = {
    AutomaticProcessedDialogs: number
    FirstAutomaticProcessedDialogStartedTime: number
    LastAutomaticProcessedDialogStartedTime: number
}

export type QueuesWithMonitoringOverviewDtoResponse = {
    Queues: Queue[]
    MonitoringOverview?: MonitoringOverview
}

export type QueuesWithMonitoringOverviewResponse = {
    Queues: Queue[]
    MonitoringOverview: MonitoringOverview
}
