import React, { useCallback } from "react"
import { Article, ArticleStatus } from "../../models/article"
import styles from "./FavoriteArticleButton.module.scss"
import Spinner from "../Spinner/Spinner"
import IconButton from "../IconButton/IconButton"
import { testId } from "../../utility/tests/testId"
import { faStar } from "@fortawesome/pro-light-svg-icons"
import cn from "classnames"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { useDispatch, useSelector } from "react-redux"
import { selectArticleFavorite, selectFavoriteArticlesBlock } from "../../store/knowledgeBase/selectors"
import { useTranslation } from "react-i18next"
import { setFavoriteArticle } from "../../store/knowledgeBase/thunks"

export type FavoriteArticleButtonProps = {
    article: Article
    categoryId?: string
    disabled: boolean
}

const tNamespace = "knowledgeBase:"

const FavoriteArticleButton: React.FC<FavoriteArticleButtonProps> = props => {
    const { article, categoryId, disabled } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isArticleFavoriteState = useSelector(selectArticleFavorite)
    const favoriteArticlesBlock = useSelector(selectFavoriteArticlesBlock)
    const isFavoriteArticle = favoriteArticlesBlock?.data?.items[article.SymbolCode]

    const handleFavoriteArticle = useCallback(
        (projectId: string, articleCode: string, isFavorite: boolean) => {
            projectId &&
                article.Title &&
                articleCode &&
                categoryId &&
                dispatch(setFavoriteArticle(projectId, articleCode, article.Title, isFavorite, categoryId))
        },
        [article.Title, categoryId, dispatch]
    )

    return (
        <TooltipTrigger
            id="favorite-article-tooltip"
            placement="bottom"
            content={t(`${tNamespace}article-actions.set-favorite-article-disabled`)}
            condition={article.Status === ArticleStatus.Draft}
        >
            <div className={styles.favoriteArticle}>
                {isArticleFavoriteState.inProcess && <Spinner className={styles.favoriteArticle__spinner} />}
                <IconButton
                    testId={testId.favoriteArticle}
                    type="submit"
                    variant="link"
                    icon={faStar}
                    className={cn(
                        styles.favoriteArticle__action,
                        isFavoriteArticle && styles.favoriteArticle__icon_favorited
                    )}
                    disabled={disabled || article.Status === ArticleStatus.Draft}
                    iconClassName={styles.favoriteArticle__icon}
                    onClick={() => handleFavoriteArticle(article.ProjectId, article.SymbolCode, !isFavoriteArticle)}
                >
                    {t(`${tNamespace}article-actions.favorite`)}
                </IconButton>
            </div>
        </TooltipTrigger>
    )
}

export default FavoriteArticleButton
