import React, { useEffect } from "react"
import styles from "./DialogMessageInputHeader.module.scss"
import DialogPath from "../DialogPath/DialogPath"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileSearch } from "@fortawesome/pro-light-svg-icons"
import usePermissionsCheck from "../../../utility/common/usePermissionsCheck"
import { ViewNewKnowledgeBase } from "../../../permissions"
import { useSelector } from "react-redux"
import { selectIsArticleSidebarOpened, selectIsKnowledgeBaseSidebarOpened } from "../../../store/dialogs/selectors"
import { useGetDialogApiCachedEndpointData } from "../../../api/selectors"
import { IGetOperatorChannelsResponse } from "../../../models/Dialogs/dialog"
import { actions } from "../../../store/dialogs/slice"
import { useAppDispatch } from "../../../store/store"
import { useTranslation } from "react-i18next"
import { ApiMessagesDU } from "../../../models/Dialogs/message"

const tInfoNamespace = "dialogs:info"

export interface DialogMessageInputHeaderProps {
    dialogId?: string
    allUserChannelsInfo?: IGetOperatorChannelsResponse[]
    onChannelSelect?: (name: string) => void
    messages?: ApiMessagesDU[]
}

const DialogMessageInputHeader: React.FC<DialogMessageInputHeaderProps> = props => {
    const { dialogId, allUserChannelsInfo, messages, onChannelSelect } = props
    const viewNewKnowledgeBaseAllowed = usePermissionsCheck([ViewNewKnowledgeBase])
    const isKnowledgeBaseOpen = useSelector(selectIsKnowledgeBaseSidebarOpened)
    const isArticleOpen = useSelector(selectIsArticleSidebarOpened)
    const isKnowledgeBaseActive = isKnowledgeBaseOpen || isArticleOpen
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const getDialogQueryCachedData = useGetDialogApiCachedEndpointData({
        endpoint: "getDialog",
        selectArgs: dialogId
    })

    const handleOpenKnowledgeBaseSidebar = () => {
        dispatch(actions.closeArticleSidebar())
        dispatch(actions.openKnowledgeBaseSidebar())
    }

    const lastMessageEntity = messages
        ?.filter(x => "AddedDate" in x.Fields)
        .sort((a, b) => {
            if ("AddedDate" in a.Fields && "AddedDate" in b.Fields) {
                return b.Fields.AddedDate - a.Fields.AddedDate
            }

            return 0
        })

    const lastMessageChannelId =
        lastMessageEntity?.length && "ChannelId" in lastMessageEntity[0].Fields
            ? lastMessageEntity[0].Fields.ChannelId
            : null

    const lastMessageChannelEntity = allUserChannelsInfo?.find(x => x.channel_id === lastMessageChannelId)

    useEffect(() => {
        if (!onChannelSelect || !allUserChannelsInfo?.length) {
            return
        }

        if (lastMessageChannelId) {
            onChannelSelect(lastMessageChannelId)
        } else if (allUserChannelsInfo?.length) {
            onChannelSelect(allUserChannelsInfo[0].channel_id)
        }
    }, [lastMessageChannelId, allUserChannelsInfo])

    return (
        <div className={styles.inputHeader}>
            {getDialogQueryCachedData.data ? (
                <DialogPath
                    project={getDialogQueryCachedData.data.Project}
                    channel={getDialogQueryCachedData.data.Channel}
                    onChannelSelect={onChannelSelect}
                    channels={allUserChannelsInfo}
                    showDropdownArrow
                />
            ) : (
                <DialogPath
                    onChannelSelect={onChannelSelect}
                    channels={allUserChannelsInfo}
                    channel={
                        lastMessageChannelEntity
                            ? {
                                  Id: lastMessageChannelEntity.channel_id,
                                  Title: lastMessageChannelEntity.channel_title,
                                  Type: lastMessageChannelEntity.channel_type
                              }
                            : null
                    }
                    showDropdownArrow
                />
            )}
            {viewNewKnowledgeBaseAllowed && (
                <button className={styles.inputHeader__knowledgeBaseButton} onClick={handleOpenKnowledgeBaseSidebar}>
                    <FontAwesomeIcon
                        icon={faFileSearch}
                        className={isKnowledgeBaseActive ? styles.inputHeader__icon_active : styles.inputHeader__icon}
                    />
                    <span
                        className={
                            isKnowledgeBaseActive ? styles.inputHeader__icon_active : styles.inputHeader__iconText
                        }
                    >
                        {t(`${tInfoNamespace}.knowledge-base`)}
                    </span>
                </button>
            )}
        </div>
    )
}

export default DialogMessageInputHeader
