import { DeepPartial } from "redux"

export interface ProjectSettings {
    DialogTimeout?: DialogTimeoutProjectSettings
    Operator?: OperatorProjectSettings
    Statistics?: StatisticsProjectSettings
    Report?: ReportProjectSettings
    Agent?: AgentProjectSettings
    Security?: SecurityProjectSettings
    System?: SystemProjectSettings
}

export const isProjectSettings = (rawData: unknown): rawData is ProjectSettings => {
    return typeof rawData === "object" && rawData !== null
}

export interface DialogTimeoutProjectSettings {
    FirstTimeout?: TimeoutSettings
    SecondTimeout?: TimeoutSettings
    FinishDialogTimeout?: TimeoutSettings
    DialogInQueueTimeout?: TimeoutSettings
    DialogLifeTimeout?: TimeoutSettings
}

export interface TimeoutSettings {
    Value: number
    Message?: string
}

export const isTimeoutSettings = (setting: unknown): setting is TimeoutSettings => {
    if (typeof setting !== "object" || setting === null) {
        return false
    }

    return "Value" in setting
}

export interface OperatorProjectSettings {
    Common?: CommonOperatorProjectSettings
    ClientSurvey?: ClientSurveyOperatorProjectSettings
    DialogSurvey?: DialogSurveyOperatorProjectSettings
    KPI?: KPIOperatorProjectSettings
    Workplace?: WorkplaceOperatorProjectSettings
    NewWorkplace?: NewWorkplaceOperatorProjectSettings
    AutoGreeting?: AutoGreetingOperatorProjectSettings
    ResponseTemplate?: ResponseTemplateOperatorProjectSettings
    Hold?: HoldOperatorProjectSettings
    Notifications?: NotificationsOperatorProjectSettings
    OutgoingDialog?: OutgoingDialogOperatorProjectSettings
}

export interface CommonOperatorProjectSettings {
    AllowChatJoinRequests?: boolean
    AuditorDashboardSurveyId?: string
}

export interface ClientSurveyOperatorProjectSettings {
    MainSurveyId?: string
    ShortSurveyId?: string
    PhoneNumberMask?: string
}

export interface DialogSurveyOperatorProjectSettings {
    ChooseTopicToFinishDialogRequired?: boolean
    DialogTopicCatalogId?: string
    DialogProgramCatalogId?: string
    DialogSurveyId?: string
    UserDissatisfaction?: ProjectSettingsListValue
    DefaultDialogTopicIdFinishingByTimeout?: string
    ChooseProgramFieldName?: string
}

export interface ProjectSettingsListValue {
    Values: string[]
}

export interface KPIOperatorProjectSettings {
    DialogTimerEnabled?: boolean
    GreenDialogTimerTimeout?: number
    YellowDialogTimerTimeout?: number
    DialogWaitingForClientAnswerTimerEnabled?: boolean
    DialogWaitingForClientAnswerTimeout?: number
    DialogWaitingForOperatorAnswerTimerEnabled?: boolean
    DialogWaitingForOperatorAnswerTimeout?: number
    DialogTimerTimeoutPlusEnabled?: boolean
    DialogTimerTimeoutCustomLabelEnabled?: boolean
    DialogTimerTimeoutCustomLabelEnabledName?: string
}

export interface WorkplaceOperatorProjectSettings {
    ShowDialogHistory?: boolean
    ForwardingMessage?: boolean
    RespondWithArticleEnabled?: boolean
    UseSpellChecker?: boolean
    UseEmoji?: boolean
    SetPreviousTopicsAfterFinishDialog?: boolean
    LoadHistoryByEmail?: boolean
    LoadHistoryByPhoneNumber?: boolean
}

export interface NewWorkplaceOperatorProjectSettings {
    CustomSections: CustomSectionsValue
    ClientsSectionName: string
    DialogSurveyId: string
    ClientSurveyId: string
    EnableSeparateNewAndInProgressDialogs: boolean
    SendMessageActionsAsButtons: SendMessageButtonsValues
}

export interface AutoGreetingOperatorProjectSettings {
    OperatorAutoGreetingEnabled: boolean
    OperatorAutoGreetingMale: string
}

export interface ResponseTemplateOperatorProjectSettings {
    FastReplyTemplates: string
    FastReplyHoldTemplates: ProjectSettingsListValue
    WaitingForClientResponseTimeout: number
    WaitingForClientResponseTimeoutMessages: ProjectSettingsListValue
    TriggerMessagesForRunClientHolding: ProjectSettingsListValue
    ClientHoldingTimeout: number
    ClientHoldingMessages: ProjectSettingsListValue
}

export interface HoldOperatorProjectSettings {
    AutoHoldingEnabled?: boolean
    DropDialogTimeout?: number
    FinishDialogWithoutClientResponseTimeout?: number
}

export interface NotificationsOperatorProjectSettings {
    PushNotificationEnabled?: boolean
    PushNotificationVolumeLevel?: number
    PushNotificationTimeout?: number
}

export interface OutgoingDialogOperatorProjectSettings {
    FinishOutgoingDialogAfterStart?: boolean
    OutgoingMessageIntentsRecordTypes?: ProjectSettingsListValue
}

export interface StatisticsProjectSettings {
    FunnelSlots: ProjectSettingsListValue
    UnproductiveAnswers: ProjectSettingsListValue
    TimeRangeStartDefiningField: StatisticsPeriodOptions
    TimeRangeEndDefiningField: StatisticsPeriodOptions
    HandledCountField: StatisticsPeriodOptions
    ExcludedOperatorIds: ProjectSettingsListValue
    FCR: number
    FCRSeparateDifferentTopics: boolean
    SLEndDefiningField: StatisticsCalculatingOptions
    SLPeriod: number
    AHTStartDefiningField: StatisticsCalculatingOptions
}

export enum StatisticsPeriodOptions {
    Started = "started",
    Finished = "finished"
}

export enum StatisticsCalculatingOptions {
    FirstReply = "first_reply",
    Routed = "routed"
}

export interface ReportProjectSettings {
    CommonReportTimeZone: number
    CommonReportSlots: CommonReportSlotsValue
}

export interface CommonReportSlotsValue {
    Values: CommonReportSlotValue[]
}

export interface CommonReportSlotValue {
    SlotId: string
    ColumnTitle: string
}

export interface CustomSectionValue {
    Id: string
    Title: string
    Url: string
    ExpandByDefault: boolean
    FullHeight: boolean
}

export interface CustomMenuValue {
    Id: string
    Title: string
    Url: string
    Icon: string
}

export interface SendMessageButtonsValues {
    Hold: boolean
}

export interface CustomSectionsValue {
    Values: CustomSectionValue[]
}

export interface CustomMenusValue {
    Values: CustomMenuValue[]
}

export interface AgentProjectSettings {
    DefaultAgent: string
    DefaultAgentForOutgoingDialog: string
    OperatorAgent: string
    SystemEventsAgent: string
}

export interface SecurityProjectSettings {
    InformationMaskingPatterns: ProjectSettingsListValue
}

export interface KnowledgeBaseConvertTemplateModel {
    FileName: string
    FileUrl: string
}

export interface SystemProjectSettings {
    UpdateClientDataFromChannel: boolean
    ChannelOnDialogPanel: boolean
    OperatorQuestionaryId: string
    DialogSwitchNotificationWebhook: string
    SwitchToBotWindowTitle: string
    SwitchToBotWindowMessage: string
    DisableMarkupModal: boolean
    KnowledgeBaseConvertTemplate: KnowledgeBaseConvertTemplateModel
    EnableMessageSendBehaviour: boolean
    AdditionalResendConfigDependsOnQueue: boolean
    CustomMenus: CustomMenusValue
}

export interface GetSettingsResponse {
    Settings: ProjectSettings
}

export interface DiffUpdateSettingsResponse {
    Diff: DeepPartial<ProjectSettings>
}
