import { TenantQueues } from "../../models/operator"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import {
    Operator,
    OperatorStatusUpdatedMsg,
    OperatorBecameActiveMsg,
    OperatorBecameInactiveMsg
} from "../../models/operator"
import { MessagePreview, Task, TaskDetachedMsg, TaskPreviewUpdatedMsg } from "../../models/task"

export type OperatorsState = Readonly<{
    getOperators: AsyncState<void>
    operators: Operator[]
    deleteFromQueue: AsyncState<void>
    updateQueues: AsyncState<void>
    makeOffline: AsyncState<void>
}>

const initialState: OperatorsState = {
    getOperators: AsyncState.create(),
    operators: [],
    deleteFromQueue: AsyncState.create(),
    updateQueues: AsyncState.create(),
    makeOffline: AsyncState.create()
}

interface AddTaskPayload {
    Task: Task
    OperatorId: string
}

interface DeleteFromQueuePayload {
    OperatorId: string
    TenantId: string
    QueueId: string
}

interface UpdateQueuesPayload {
    OperatorId: string
    TenantQueues: TenantQueues
}

const addTask = (operator: Operator, task: Task) => {
    return {
        ...operator,
        Tasks: [...operator.Tasks, task]
    }
}

const detachTask = (operator: Operator, taskId: string) => {
    return {
        ...operator,
        Tasks: operator.Tasks.filter(t => t.Id !== taskId)
    }
}

const deleteQueueFromTenant = (tenantQueues: TenantQueues, queueId: string): TenantQueues => {
    return {
        ...tenantQueues,
        Queues: tenantQueues.Queues.filter(v => v !== queueId)
    }
}

const deleteOperatorFromQueue = (operator: Operator, tenantId: string, queueId: string): Operator => {
    return {
        ...operator,
        TenantsQueues: operator.TenantsQueues.map(v =>
            v.TenantId === tenantId ? deleteQueueFromTenant(v, queueId) : v
        )
    }
}

const updateQueues = (operator: Operator, tenantQueues: TenantQueues): Operator => {
    return {
        ...operator,
        TenantsQueues: operator.TenantsQueues.map(v => (v.TenantId === tenantQueues.TenantId ? tenantQueues : v))
    }
}

const updateTaskPreview = (tasks: Task[], updateTaskId: string, lastMessage: string) => {
    const updatePreview: MessagePreview = {
        Text: lastMessage
    }
    return tasks.map(task => (task.Id !== updateTaskId ? task : { ...task, Preview: updatePreview }))
}

const operators = createSlice({
    name: "operators",
    initialState,
    reducers: {
        getOperatorsProcess(state) {
            state.getOperators = state.getOperators.toProcess()
        },
        getOperatorsSuccess(state, action: PayloadAction<Operator[]>) {
            state.operators = action.payload
            state.getOperators = state.getOperators.toSuccess()
        },
        getOperatorsFailed(state, action: PayloadAction<SystemError>) {
            state.getOperators = state.getOperators.toFailed(action.payload)
        },
        detachTask(state, action: PayloadAction<TaskDetachedMsg>) {
            state.operators = state.operators.map(o => {
                if (o.Id !== action.payload.OperatorId) {
                    return o
                }

                return detachTask(o, action.payload.TaskId)
            })
        },
        addTask(state, action: PayloadAction<AddTaskPayload>) {
            state.operators = state.operators.map(o => {
                if (o.Id !== action.payload.OperatorId) {
                    return o
                }

                return addTask(o, action.payload.Task)
            })
        },
        updateOperatorsTaskPreview(state, action: PayloadAction<TaskPreviewUpdatedMsg>) {
            const updateMessage = action.payload
            state.operators = state.operators.map(o => {
                return {
                    ...o,
                    Tasks: updateTaskPreview(o.Tasks, updateMessage.TaskId, updateMessage.Text)
                }
            })
        },
        updateOperatorStatus(state, action: PayloadAction<OperatorStatusUpdatedMsg>) {
            state.operators = state.operators.map(o => {
                if (o.Id !== action.payload.OperatorId) {
                    return o
                }

                return { ...o, Status: action.payload.Status }
            })
        },
        addActiveOperator(state, action: PayloadAction<OperatorBecameActiveMsg>) {
            const newOperator = action.payload.Operator
            const operators = [...state.operators]
            const operatorIndex = operators.findIndex(o => o.Id === newOperator.Id)
            if (operatorIndex !== -1) {
                operators[operatorIndex] = newOperator
            } else {
                operators.push(newOperator)
            }
            state.operators = operators
        },
        removeInactiveOperator(state, action: PayloadAction<OperatorBecameInactiveMsg>) {
            state.operators = state.operators.filter(o => o.Id !== action.payload.OperatorId)
        },
        deleteFromQueueProcess(state) {
            state.deleteFromQueue = state.deleteFromQueue.toProcess()
        },
        deleteFromQueueSuccess(state, action: PayloadAction<DeleteFromQueuePayload>) {
            state.deleteFromQueue = state.deleteFromQueue.toSuccess()
            state.operators = state.operators.map(o => {
                if (o.Id !== action.payload.OperatorId) {
                    return o
                }

                return deleteOperatorFromQueue(o, action.payload.TenantId, action.payload.QueueId)
            })
        },
        deleteFromQueueFailed(state, action: PayloadAction<SystemError>) {
            state.deleteFromQueue = state.deleteFromQueue.toFailed(action.payload)
        },
        updateQueuesProcess(state) {
            state.updateQueues = state.updateQueues.toProcess()
        },
        updateQueuesSuccess(state, action: PayloadAction<UpdateQueuesPayload>) {
            state.updateQueues = state.updateQueues.toSuccess()
            state.operators = state.operators.map(o => {
                if (o.Id !== action.payload.OperatorId) {
                    return o
                }

                return updateQueues(o, action.payload.TenantQueues)
            })
        },
        updateQueuesFailed(state, action: PayloadAction<SystemError>) {
            state.updateQueues = state.updateQueues.toFailed(action.payload)
        },
        makeOfflineProcess(state) {
            state.makeOffline = state.makeOffline.toProcess()
        },
        makeOfflineSuccess(state) {
            state.makeOffline = state.makeOffline.toSuccess()
        },
        makeOfflineFailed(state, action: PayloadAction<SystemError>) {
            state.makeOffline = state.makeOffline.toFailed(action.payload)
        }
    }
})

export default operators.reducer

export const actions = operators.actions
