import React, { Ref } from "react"
import { ApiMessagesDU } from "../../../models/Dialogs/message"
import MessageBasic from "./components/MessageBasic/MessageBasic"
import MessageEndDialog from "./components/MessageEndDialog/MessageEndDialog"
import { TSenderSignature } from "../DialogChat/DialogChat"
import { IGetOperatorChannelsResponse } from "../../../models/Dialogs/dialog"

export type DialogMessageProps = {
    message: ApiMessagesDU
    className?: string
    lastMessageRef?: Ref<HTMLDivElement>
    sender?: TSenderSignature
    channel?: IGetOperatorChannelsResponse
}

const DialogMessage = ({ message, className, lastMessageRef, sender, channel }: DialogMessageProps) => {
    const renderMessage = () => {
        switch (message.Case) {
            case "Message":
                return (
                    <MessageBasic
                        message={message.Fields}
                        sender={sender}
                        channel={channel}
                        lastMessageRef={lastMessageRef}
                        className={className}
                    />
                )
            case "DialogFinish":
                return (
                    <MessageEndDialog message={message.Fields} lastMessageRef={lastMessageRef} className={className} />
                )
            default:
                return <></>
        }
    }

    return renderMessage()
}

export default DialogMessage
