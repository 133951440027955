import { useEffect, useMemo, useState } from "react"

const limit = 10000

const isMdTable = (slice: string): boolean => {
    const regexPattern = /^(\|[^\n]+\|\r?\n)((?:\|:?[-]+:?)+\|)(\n(?:\|[^\n]+\|\r?\n?)*)?$/gm
    return regexPattern.test(slice)
}

const isMdHeading = (slice: string): boolean => {
    const regexPattern = /#{1,6} .*(?=\n)/g
    return regexPattern.test(slice)
}

const isOutOfLimit = (slice: string): boolean => {
    return slice ? slice.length > limit : false
}

const makeSlice = (textBlocks: string[]) => {
    const el = textBlocks.shift() ?? ""
    return "\n\n" + el
}

/**
 * Возвращает разделенную на страницы статью, если в статье присутствуют таблицы больше, чем заданный limit
 *
 * @param {string[]} textBlocks Массив блоков статьи.
 */
export const useArticlePagination = (textBlocks: string[]) => {
    const [pageIndex, setPageIndex] = useState<number>(0)
    const [pageContent, setPageContent] = useState<string>(() => "")

    const pages = useMemo(() => {
        if (!!textBlocks.length) {
            const pages: string[] = []
            while (!!textBlocks.length && textBlocks.length !== 1) {
                let textSlice = makeSlice(textBlocks)
                while (textBlocks && !!textBlocks.length) {
                    if (isMdHeading(textBlocks[0]) && isMdTable(textBlocks[1]) && isOutOfLimit(textBlocks[1])) {
                        textSlice += makeSlice(textBlocks)
                        textSlice += makeSlice(textBlocks)
                        break
                    } else if (isMdTable(textBlocks[0]) && isOutOfLimit(textBlocks[0])) {
                        textSlice += makeSlice(textBlocks)
                        break
                    } else {
                        textSlice += makeSlice(textBlocks)
                    }
                }

                pages.push(textSlice)
            }

            if (textBlocks.length === 1 && pages.length > 0) {
                pages[pages.length - 1] += makeSlice(textBlocks)
            }

            if (textBlocks.length === 1 && pages.length === 0) {
                pages[0] = makeSlice(textBlocks)
            }

            return pages
        }

        return [""]
    }, [textBlocks])

    const onNextPage = () => {
        setPageContent(pages[pageIndex + 1])
        setPageIndex(prevState => prevState + 1)
    }
    const onPreviousPage = () => {
        setPageContent(pages[pageIndex - 1])
        setPageIndex(prevState => prevState - 1)
    }

    useEffect(() => {
        if (pages.length !== 0) {
            setPageContent(pages[pageIndex > pages.length - 1 ? 0 : pageIndex])
            if (pageIndex > pages.length - 1) {
                setPageIndex(0)
            }
        }
    }, [textBlocks, pages, pageContent])

    return { pages, pageIndex, pageContent, onNextPage, onPreviousPage }
}
