import React, { useCallback, useEffect, useMemo, useState } from "react"
import { DialogProject, DialogQueue, IGetOperatorChannelsResponse } from "../../../models/Dialogs/dialog"
import { ChannelTypeString, SimpleChannel } from "../../../models/channel"
import styles from "./DialogPath.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/pro-light-svg-icons/faAngleRight"
import ChannelIcon from "../../ChannelIcon/ChannelIcon"
import { faSquare } from "@fortawesome/pro-light-svg-icons/faSquare"
import { Dropdown } from "../../Dropdown"
import { IDropdownItem } from "../../Dropdown/Dropdown"
import { channelTypeStringConverter } from "../../../utility/channels/channelTypeStringConverter"
import cn from "classnames"
import { testId } from "../../../utility/tests/testId"

export interface DialogPathProps {
    channels?: IGetOperatorChannelsResponse[]
    project?: DialogProject
    channel?: SimpleChannel | null
    queue?: DialogQueue
    channelsDropdownHidden?: boolean
    onChannelSelect?: (name: string) => void
    onNewChannel?: () => void
    showDropdownArrow?: boolean
    className?: string
}

const DialogPath: React.FC<DialogPathProps> = props => {
    const {
        project,
        channel,
        queue,
        channelsDropdownHidden,
        onChannelSelect,
        channels = [],
        showDropdownArrow,
        className
    } = props

    const [selectedChannel, setSelectedChannel] = useState<SimpleChannel>({
        Id: "webchat",
        Title: "WebChat",
        Type: ChannelTypeString.WebChat
    })

    const renderChannelListItem = (item: IGetOperatorChannelsResponse) => {
        const type = channelTypeStringConverter.toChannelType(item.channel_type)

        return (
            <div className={styles.dialogPath__channel_item}>
                <ChannelIcon type={type} className={styles.dialogPath__channel_picture} />
                <span className={styles.dialogPath__channel_title}>{item.channel_title}</span>
            </div>
        )
    }

    const handleChannelSelect = useCallback(
        (id: string) => {
            if (onChannelSelect) {
                onChannelSelect(id)
            }
            const channelEntity = channels.find(x => x.channel_id === id)

            if (channelEntity) {
                setSelectedChannel({
                    Id: channelEntity.channel_id,
                    Title: channelEntity.channel_title,
                    Type: channelEntity.channel_type
                })
            }
        },
        [onChannelSelect, channels]
    )

    const channelsList = useMemo<IDropdownItem[]>(
        () =>
            channels.map(x => ({
                name: x.channel_id,
                title: renderChannelListItem(x)
            })),
        [channels]
    )

    useEffect(() => {
        if (!channels.length) {
            return
        }

        if (channel) {
            const selectedChannel = {
                ...channel
            }

            if (channelsDropdownHidden) {
                const channelByType = channels.find(x => x.channel_type === channel.Type)
                selectedChannel.Title = channelByType?.channel_title ?? ""
            }

            setSelectedChannel(selectedChannel)
        } else {
            const firstChannel = channels[0]

            setSelectedChannel({
                Id: firstChannel.channel_id,
                Title: firstChannel.channel_title,
                Type: firstChannel.channel_type
            })
        }
    }, [channel, channels, channelsDropdownHidden])

    return (
        <div className={cn(styles.dialogPath, className)}>
            <div className={cn(styles.dialogPath__item, className)}>
                {project?.Picture ? (
                    <img src={project.Picture} alt="project" className={styles.dialogPath__picture} />
                ) : (
                    <FontAwesomeIcon icon={faSquare} className={styles.dialogPath__defaultPicture} />
                )}
                <div
                    className={
                        showDropdownArrow
                            ? styles.dialogPath__projectTitle_messageInputHeader
                            : styles.dialogPath__projectTitle
                    }
                >
                    {project?.Title}
                </div>
            </div>
            <FontAwesomeIcon icon={faAngleRight} className={styles.dialogPath__pointer} />
            <Dropdown
                items={channelsList}
                throughMode={channelsDropdownHidden}
                onListItemSelect={handleChannelSelect}
                showArrowIcon={showDropdownArrow}
            >
                <div className={cn(styles.dialogPath__item, className)} data-testid={testId.dialogPathSelectedChannel}>
                    <ChannelIcon
                        type={channelTypeStringConverter.toChannelType(selectedChannel.Type)}
                        className={styles.dialogPath__channel_picture}
                    />
                    <div
                        className={
                            showDropdownArrow
                                ? styles.dialogPath__projectTitle_messageInputHeader
                                : styles.dialogPath__projectTitle
                        }
                    >
                        {selectedChannel.Title}
                    </div>
                </div>
            </Dropdown>
            {queue?.Title && (
                <div className={cn(styles.dialogPath__item, className)}>
                    <FontAwesomeIcon icon={faAngleRight} className={styles.dialogPath__pointer} />
                    <div>{queue.Title}</div>
                </div>
            )}
        </div>
    )
}

export default DialogPath
