import React, { RefObject, useCallback, useContext, useEffect, useMemo } from "react"
import cn from "classnames"
import {
    Article as KnowledgeBaseArticle,
    ArticleAnswer,
    ArticleEditFormValues,
    ArticleStatus as Status
} from "../../models/article"
import ArticleEditForm from "../ArticleEditForm/ArticleEditForm"
import Article from "../Article/Article"
import { useTranslation } from "react-i18next"
import { ArticleParameters } from "../ArticleParameters/ArticleParameters"
import { ArticleType } from "../ArticleType/ArticleType"
import { ArticleTags } from "../ArticleTags/ArticleTags"
import ArticleStatus from "../ArticleStatus/ArticleStatus"
import ArticleUserViewsPopoverContainer from "../ArticleUserViewsPopover/ArticleUserViewsPopoverContainer"
import ArticleCommentsViewContainer from "../ArticleCommentsView/ArticleCommentsViewContainer"
import { FormikProps } from "formik/dist/types"
import ArticleReadConfirmationCardContainer from "../ArticleReadConfirmationCard/ArticleReadConfirmationCardContainer"
import "./ArticleView.scss"
import { Route, RouteComponentProps } from "react-router"
import { useParams, useRouteMatch } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
    selectAnswersFrom,
    selectArticleExpanded,
    selectArticleFormQuestions,
    selectArticleTypes,
    selectCurrentAnswer
} from "../../store/knowledgeBase/selectors"
import ArticleToolbar from "../ArticleToolbar/ArticleToolbar"
import { ArticleContext } from "../../pages/KnowledgeBase/KnowledgeBase"
import SubArticlesContainer from "../SubArticles/SubArticlesContainer"
import CurrentBranchContainer from "../CurrentBranch/CurrentBranchContainer"
import type { TreeItem } from "@atlaskit/tree/types"
import { getSlots } from "../../store/slots/thunks"
import { selectSlots } from "../../store/slots/selectors"
import ArticleContentEditor from "../ArticleContentEditor/ArticleContentEditor"
import { testId } from "../../utility/tests/testId"
import { ArticleTableOfContent, trigProsemirrorReadyEvent } from "../ArticleTableOfContent"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { ArticleType as TArticleType } from "../../models/articleType"
import Pagination from "../Pagination/Pagination"
import { useArticlePagination } from "../../utility/common/useArticlePagination"
import { CatalogPlacement } from "../Catalogs/Catalogs"
import KnowledgeBaseContext from "../KnowledgeBaseProvider/KnowledgeBaseContext"
import { SharedType } from "../../models/sharing"

const tNamespace = "knowledgeBase:"
const ARTICLE_TYPE_NAME_MAX_LENGTH = 22

export interface ArticleViewProps {
    article: KnowledgeBaseArticle
    formRef?: RefObject<FormikProps<ArticleEditFormValues>>
    isEdit: boolean
    canEdit: boolean
    notificationExists: boolean
    onUpdateArticle?: (values: ArticleEditFormValues) => void
    onTransformToScenario?: () => void
    onSelectAnswer: (answerId: string) => void
    onUpdateAnswer?: (answer: ArticleAnswer) => void
    onChangeCurrentAnswer: (answerId: string) => void
    onRelatedArticleClick: (category: TreeItem) => void
    actions?: JSX.Element
    articlePlacement?: CatalogPlacement
}

const ArticleView: React.FC<ArticleViewProps> = props => {
    const { path } = useRouteMatch()
    return <Route path={`${path}/:answerId?`} render={routeProps => <ArticleViewRoute {...routeProps} {...props} />} />
}

export type ArticleViewMatchPath = {
    answerId?: string
    code?: string
}

type ArticleViewRouteProps = ArticleViewProps & RouteComponentProps<ArticleViewMatchPath>

export const ArticleViewRoute: React.FC<ArticleViewRouteProps> = props => {
    const {
        article,
        formRef,
        isEdit,
        canEdit,
        notificationExists,
        onUpdateArticle,
        onTransformToScenario,
        onSelectAnswer,
        onRelatedArticleClick,
        onUpdateAnswer,
        onChangeCurrentAnswer,
        actions,
        articlePlacement
    } = props

    const { t } = useTranslation()
    const { answerId, code } = useParams<ArticleViewMatchPath>()
    const { onChooseAnswer, onOpenQuestions } = useContext(ArticleContext)
    const answersForm = useSelector(selectAnswersFrom)
    const currentAnswer = useSelector(selectCurrentAnswer)
    const questions = useSelector(selectArticleFormQuestions)
    const slots = useSelector(selectSlots)
    const dispatch = useDispatch()
    const articleExpanded = useSelector(selectArticleExpanded)
    const { shareId, sharedType } = useContext(KnowledgeBaseContext)

    const handleOnEditorInit = useCallback(trigProsemirrorReadyEvent, [])
    const handleDocStateChange = useCallback(trigProsemirrorReadyEvent, [])

    useEffect(() => {
        if (slots.length === 0 && article && article.ProjectId) {
            dispatch(getSlots(article.ProjectId))
        }
    }, [article, dispatch, slots.length])

    useEffect(() => {
        if (answersForm.Answers.length === 0) {
            return
        }
        const answers = answersForm.Answers

        if (articlePlacement === CatalogPlacement.workplace) {
            if (!currentAnswer && answers.length) {
                onSelectAnswer(answers[0].Id)
            }
        } else {
            /*
          Логика перехода по возможным ответам (читай "контенту") статьи в pages/KnowledgeBase
          Абсолютно нечитаемая, размазанная по контексту, редаксу, стейту сайдбара, реакт дому и по куче пропсов
          TODO: переделать
        */
            if (!answerId && code === article.SymbolCode) {
                onSelectAnswer(answers[0].Id)
            } else {
                if (currentAnswer?.Id !== answerId && answerId) {
                    onChangeCurrentAnswer(answerId)
                }
            }
        }
    }, [
        answerId,
        answersForm,
        article.SymbolCode,
        articlePlacement,
        code,
        currentAnswer,
        onChangeCurrentAnswer,
        onSelectAnswer
    ])

    const handleOpenAnswers = () => {
        onChooseAnswer(false, false, onSelectAnswer)
    }
    const handleOpenQuestions = canEdit ? () => onOpenQuestions(false) : undefined

    const textArr: string[] = useMemo(() => currentAnswer?.Text.split("\n\n") ?? [], [currentAnswer?.Text])

    const { pages, pageIndex, pageContent, onNextPage, onPreviousPage } = useArticlePagination(textArr)

    const articleTypesResponse = useSelector(selectArticleTypes)
    const articleTypes = articleTypesResponse?.Types?.length ? [...articleTypesResponse.Types] : []
    const tooltipArticleType = articleTypes.find(t => t.Id === article.Type) as TArticleType

    const isCatalogShared = sharedType && sharedType === SharedType.Catalog

    return (
        <div
            className={cn(
                "article-view",
                articlePlacement === CatalogPlacement.workplace && "article-view_new-workplace"
            )}
        >
            {articlePlacement !== CatalogPlacement.workplace && (
                <div className="article-view__header">
                    {!(shareId && !isCatalogShared) && <CurrentBranchContainer onParentClick={onRelatedArticleClick} />}
                    {actions}
                </div>
            )}
            <div className="article-view__body">
                {isEdit && onUpdateArticle && formRef ? (
                    <ArticleEditForm
                        article={article}
                        answersForm={answersForm}
                        currentAnswer={currentAnswer}
                        formRef={formRef}
                        onSubmit={onUpdateArticle}
                        onTransformToScenario={onTransformToScenario}
                        onUpdateAnswer={onUpdateAnswer}
                        onSelectAnswer={onSelectAnswer}
                        questionsCount={questions.length}
                        isArticleExpanded={articleExpanded}
                    />
                ) : (
                    <>
                        <Article
                            symbolCode={article.SymbolCode}
                            prepended={
                                articlePlacement !== CatalogPlacement.workplace && (
                                    <>
                                        <ArticleTableOfContent />
                                    </>
                                )
                            }
                            headerTop={
                                articlePlacement !== CatalogPlacement.workplace && (
                                    <>
                                        <TooltipTrigger
                                            id={article.Type}
                                            placement="top"
                                            content={tooltipArticleType?.Title}
                                            condition={tooltipArticleType?.Title.length > ARTICLE_TYPE_NAME_MAX_LENGTH}
                                        >
                                            <div>
                                                <ArticleType type={article.Type} />
                                            </div>
                                        </TooltipTrigger>
                                        <ArticleTags tags={article.Tags} />
                                    </>
                                )
                            }
                            headerRight={
                                articlePlacement !== CatalogPlacement.workplace &&
                                (article.Status === Status.Active ? (
                                    <ArticleUserViewsPopoverContainer currentArticleCode={article.SymbolCode} />
                                ) : (
                                    <ArticleStatus status={article.Status} />
                                ))
                            }
                            title={article.Title ? article.Title : t(`${tNamespace}untitled`)}
                            withoutHeader={articlePlacement === CatalogPlacement.workplace}
                            widgets={
                                <>
                                    <ArticleToolbar
                                        onOpenAnswers={handleOpenAnswers}
                                        answersCount={answersForm.Answers.length}
                                        onOpenQuestions={handleOpenQuestions}
                                        questionsCount={questions.length}
                                    />
                                    <ArticleParameters parameters={article.Parameters} />
                                    {pages.length > 1 && (
                                        <Pagination
                                            forArticle
                                            pageIndex={pageIndex}
                                            total={pages.length}
                                            onNextPage={onNextPage}
                                            canNextPage={pageIndex + 1 !== pages.length}
                                            onPreviousPage={onPreviousPage}
                                            canPreviousPage={pageIndex !== 0}
                                            resultsPerPage={1}
                                        />
                                    )}
                                </>
                            }
                            body={
                                <>
                                    <ArticleContentEditor
                                        onInit={handleOnEditorInit}
                                        onDocStateChange={handleDocStateChange}
                                        content={pageContent}
                                        isEditable={false}
                                        t={t}
                                    />
                                </>
                            }
                            isExpanded={articleExpanded}
                            testId={testId.article}
                        />
                        {!isEdit && notificationExists && (
                            <div className="article-view__read-confirmation">
                                <ArticleReadConfirmationCardContainer articleCode={article.SymbolCode} />
                            </div>
                        )}
                        {!(shareId && !isCatalogShared) && (
                            <SubArticlesContainer onSubArticleClick={onRelatedArticleClick} />
                        )}
                        <ArticleCommentsViewContainer articleCode={article.SymbolCode} articleTitle={article.Title} />
                    </>
                )}
            </div>
        </div>
    )
}

export default ArticleView
