import {
    DialogSurveyOperatorProjectSettings,
    HoldOperatorProjectSettings,
    KPIOperatorProjectSettings,
    NewWorkplaceOperatorProjectSettings,
    NotificationsOperatorProjectSettings,
    OperatorProjectSettings,
    ProjectSettings,
    ResponseTemplateOperatorProjectSettings
} from "../../../models/projectSettings"
import {
    ChooseTopicToFinishDialogType,
    DialogSurveyOperatorProjectSettingsValues,
    KPIOperatorProjectSettingsValues,
    OperatorsWorkValues
} from "../../../models/projectSettingsValues"
import { durationSettingsValuesToMs, msToDurationSettingsValues } from "../../../utility/project/projectSettings"
import { DeepRequired } from "../../../types/utilityTypes"
import { v4 as uuidv4 } from "uuid"

export const PushNotificationVolumeLevelBounds = {
    Min: 0,
    Max: 10,
    Step: 1
}

const coefficientPushNotificationVolumeLevel =
    PushNotificationVolumeLevelBounds.Max - PushNotificationVolumeLevelBounds.Min

export const defaultOperatorProjectSettings: DeepRequired<OperatorProjectSettings> = {
    Common: {
        AllowChatJoinRequests: true,
        AuditorDashboardSurveyId: ""
    },
    ClientSurvey: {
        MainSurveyId: "",
        ShortSurveyId: "",
        PhoneNumberMask: "+7(999)-999-99-99"
    },
    DialogSurvey: {
        ChooseTopicToFinishDialogRequired: false,
        DialogTopicCatalogId: "",
        DialogProgramCatalogId: "",
        DialogSurveyId: "",
        UserDissatisfaction: {
            Values: []
        },
        DefaultDialogTopicIdFinishingByTimeout: "",
        ChooseProgramFieldName: ""
    },
    NewWorkplace: {
        CustomSections: {
            Values: []
        },
        ClientsSectionName: "",
        DialogSurveyId: "",
        ClientSurveyId: "",
        EnableSeparateNewAndInProgressDialogs: false,
        SendMessageActionsAsButtons: {
            Hold: false
        }
    },
    KPI: {
        DialogTimerEnabled: true,
        GreenDialogTimerTimeout: 300000,
        YellowDialogTimerTimeout: 240000,
        DialogWaitingForClientAnswerTimerEnabled: true,
        DialogWaitingForClientAnswerTimeout: 60000,
        DialogWaitingForOperatorAnswerTimerEnabled: false,
        DialogWaitingForOperatorAnswerTimeout: 60000,
        DialogTimerTimeoutPlusEnabled: false,
        DialogTimerTimeoutCustomLabelEnabled: false,
        DialogTimerTimeoutCustomLabelEnabledName: ""
    },
    Workplace: {
        ShowDialogHistory: true,
        ForwardingMessage: true,
        RespondWithArticleEnabled: true,
        UseSpellChecker: true,
        UseEmoji: true,
        SetPreviousTopicsAfterFinishDialog: false,
        LoadHistoryByEmail: false,
        LoadHistoryByPhoneNumber: false
    },
    AutoGreeting: {
        OperatorAutoGreetingEnabled: false,
        OperatorAutoGreetingMale: ""
    },
    ResponseTemplate: {
        FastReplyTemplates: "",
        FastReplyHoldTemplates: {
            Values: []
        },
        WaitingForClientResponseTimeout: 60000,
        WaitingForClientResponseTimeoutMessages: {
            Values: []
        },
        TriggerMessagesForRunClientHolding: {
            Values: []
        },
        ClientHoldingTimeout: 120000,
        ClientHoldingMessages: {
            Values: []
        }
    },
    Hold: {
        AutoHoldingEnabled: true,
        DropDialogTimeout: 120000,
        FinishDialogWithoutClientResponseTimeout: 10800000
    },
    Notifications: {
        PushNotificationEnabled: true,
        PushNotificationVolumeLevel: 0.5,
        PushNotificationTimeout: 1
    },
    OutgoingDialog: {
        FinishOutgoingDialogAfterStart: false,
        OutgoingMessageIntentsRecordTypes: {
            Values: []
        }
    }
}

const dialogSurveySettingsToValues = (
    settings: DeepRequired<DialogSurveyOperatorProjectSettings>
): OperatorsWorkValues["DialogSurvey"] => {
    return {
        ...settings,
        ChooseTopicToFinishDialog: settings.ChooseTopicToFinishDialogRequired
            ? ChooseTopicToFinishDialogType.Required
            : ChooseTopicToFinishDialogType.Automatic
    }
}

const processResponseTemplateTimeouts = (
    settings: DeepRequired<ResponseTemplateOperatorProjectSettings>
): OperatorsWorkValues["ResponseTemplate"] => ({
    ...settings,
    WaitingForClientResponseTimeout: msToDurationSettingsValues(settings.WaitingForClientResponseTimeout),
    ClientHoldingTimeout: msToDurationSettingsValues(settings.ClientHoldingTimeout)
})

const processHoldTimeouts = (settings: DeepRequired<HoldOperatorProjectSettings>): OperatorsWorkValues["Hold"] => ({
    ...settings,
    DropDialogTimeout: msToDurationSettingsValues(settings.DropDialogTimeout),
    FinishDialogWithoutClientResponseTimeout: msToDurationSettingsValues(
        settings.FinishDialogWithoutClientResponseTimeout
    )
})

const processNotificationsSettings = (settings: OperatorsWorkValues["Notifications"]) => {
    settings.PushNotificationVolumeLevel = settings.PushNotificationVolumeLevel * coefficientPushNotificationVolumeLevel
    return settings
}

const KPISettingsToValues = (settings: DeepRequired<KPIOperatorProjectSettings>): KPIOperatorProjectSettingsValues => ({
    DialogTimerEnabled: settings.DialogTimerEnabled,
    GreenDialogTimerTimeout: msToDurationSettingsValues(settings.GreenDialogTimerTimeout),
    YellowDialogTimerTimeout: msToDurationSettingsValues(settings.YellowDialogTimerTimeout),
    DialogWaitingForClientAnswerTimerEnabled: settings.DialogWaitingForClientAnswerTimerEnabled,
    DialogWaitingForClientAnswerTimeout: msToDurationSettingsValues(settings.DialogWaitingForClientAnswerTimeout),
    DialogWaitingForOperatorAnswerTimerEnabled: settings.DialogWaitingForOperatorAnswerTimerEnabled,
    DialogWaitingForOperatorAnswerTimeout: msToDurationSettingsValues(settings.DialogWaitingForOperatorAnswerTimeout),
    DialogTimerTimeoutPlusEnabled: settings.DialogTimerTimeoutPlusEnabled,
    DialogTimerTimeoutCustomLabelEnabled: settings.DialogTimerTimeoutCustomLabelEnabled,
    DialogTimerTimeoutCustomLabelEnabledName: settings.DialogTimerTimeoutCustomLabelEnabledName
})

export const getInitialValues = (settings: ProjectSettings): OperatorsWorkValues => {
    const commonSettings = { ...defaultOperatorProjectSettings.Common, ...settings.Operator?.Common }
    const clientSurveySettings = { ...defaultOperatorProjectSettings.ClientSurvey, ...settings.Operator?.ClientSurvey }
    const dialogSurveySettings = dialogSurveySettingsToValues({
        ...defaultOperatorProjectSettings.DialogSurvey,
        ...settings.Operator?.DialogSurvey
    })
    const KPISettings = KPISettingsToValues({ ...defaultOperatorProjectSettings.KPI, ...settings.Operator?.KPI })
    const workplaceSettings = { ...defaultOperatorProjectSettings.Workplace, ...settings.Operator?.Workplace }
    const newWorkplaceSettings = { ...defaultOperatorProjectSettings.NewWorkplace, ...settings.Operator?.NewWorkplace }
    const autoGreetingSettings = { ...defaultOperatorProjectSettings.AutoGreeting, ...settings.Operator?.AutoGreeting }
    const responseTemplateSettings = processResponseTemplateTimeouts({
        ...defaultOperatorProjectSettings.ResponseTemplate,
        ...settings.Operator?.ResponseTemplate
    })
    const holdSettings = processHoldTimeouts({ ...defaultOperatorProjectSettings.Hold, ...settings.Operator?.Hold })
    const notificationsSettings = processNotificationsSettings({
        ...defaultOperatorProjectSettings.Notifications,
        ...settings.Operator?.Notifications
    })
    const outgoingDialogSettings = {
        ...defaultOperatorProjectSettings.OutgoingDialog,
        ...settings.Operator?.OutgoingDialog
    }

    return {
        Common: commonSettings,
        ClientSurvey: clientSurveySettings,
        DialogSurvey: dialogSurveySettings,
        NewWorkplace: newWorkplaceSettings,
        KPI: KPISettings,
        Workplace: workplaceSettings,
        AutoGreeting: autoGreetingSettings,
        ResponseTemplate: responseTemplateSettings,
        Hold: holdSettings,
        Notifications: notificationsSettings,
        OutgoingDialog: outgoingDialogSettings
    }
}

export const getKPISettings = (settings: KPIOperatorProjectSettingsValues): KPIOperatorProjectSettings => {
    return {
        DialogTimerEnabled: settings.DialogTimerEnabled,
        GreenDialogTimerTimeout: durationSettingsValuesToMs(settings.GreenDialogTimerTimeout),
        YellowDialogTimerTimeout: durationSettingsValuesToMs(settings.YellowDialogTimerTimeout),
        DialogWaitingForClientAnswerTimerEnabled: settings.DialogWaitingForClientAnswerTimerEnabled,
        DialogWaitingForClientAnswerTimeout: durationSettingsValuesToMs(settings.DialogWaitingForClientAnswerTimeout),
        DialogWaitingForOperatorAnswerTimerEnabled: settings.DialogWaitingForOperatorAnswerTimerEnabled,
        DialogWaitingForOperatorAnswerTimeout: durationSettingsValuesToMs(
            settings.DialogWaitingForOperatorAnswerTimeout
        ),
        DialogTimerTimeoutPlusEnabled: settings.DialogTimerTimeoutPlusEnabled,
        DialogTimerTimeoutCustomLabelEnabled: settings.DialogTimerTimeoutCustomLabelEnabled,
        DialogTimerTimeoutCustomLabelEnabledName: settings.DialogTimerTimeoutCustomLabelEnabledName
    }
}

export const getDialogSurveySettings = (
    settings: DialogSurveyOperatorProjectSettingsValues
): DialogSurveyOperatorProjectSettings => {
    return {
        ChooseTopicToFinishDialogRequired:
            settings.ChooseTopicToFinishDialog === ChooseTopicToFinishDialogType.Required,
        DialogTopicCatalogId: settings.DialogTopicCatalogId,
        DialogProgramCatalogId: settings.DialogProgramCatalogId,
        DialogSurveyId: settings.DialogSurveyId,
        UserDissatisfaction: settings.UserDissatisfaction,
        DefaultDialogTopicIdFinishingByTimeout: settings.DefaultDialogTopicIdFinishingByTimeout,
        ChooseProgramFieldName: settings.ChooseProgramFieldName
    }
}

export const getNewWorkplaceSettings = (
    settings: OperatorsWorkValues["NewWorkplace"]
): NewWorkplaceOperatorProjectSettings => ({
    ...settings,
    CustomSections: {
        Values: settings.CustomSections.Values.map(item => {
            return {
                Id: item.Id ? item.Id : uuidv4(),
                Title: item.Title ? item.Title : "",
                Url: item.Url ? item.Url : "",
                ExpandByDefault: !!item.ExpandByDefault,
                FullHeight: !!item.FullHeight
            }
        })
    }
})

export const getResponseTemplateSettings = (
    settings: OperatorsWorkValues["ResponseTemplate"]
): ResponseTemplateOperatorProjectSettings => ({
    ...settings,
    WaitingForClientResponseTimeout: durationSettingsValuesToMs(settings.WaitingForClientResponseTimeout),
    ClientHoldingTimeout: durationSettingsValuesToMs(settings.ClientHoldingTimeout)
})

const getHoldSettings = (settings: OperatorsWorkValues["Hold"]): HoldOperatorProjectSettings => ({
    ...settings,
    DropDialogTimeout: durationSettingsValuesToMs(settings.DropDialogTimeout),
    FinishDialogWithoutClientResponseTimeout: durationSettingsValuesToMs(
        settings.FinishDialogWithoutClientResponseTimeout
    )
})

const getNotificationsSettings = (
    settings: OperatorsWorkValues["Notifications"]
): NotificationsOperatorProjectSettings => ({
    ...settings,
    PushNotificationVolumeLevel: settings.PushNotificationVolumeLevel / coefficientPushNotificationVolumeLevel
})

export const buildOperatorsWorkSettingsRequest = (values: OperatorsWorkValues) => ({
    Operator: {
        ...values,
        DialogSurvey: getDialogSurveySettings(values.DialogSurvey),
        NewWorkplace: getNewWorkplaceSettings(values.NewWorkplace),
        KPI: getKPISettings(values.KPI),
        ResponseTemplate: getResponseTemplateSettings(values.ResponseTemplate),
        Hold: getHoldSettings(values.Hold),
        Notifications: getNotificationsSettings(values.Notifications)
    }
})
